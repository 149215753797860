

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import type Day from '@/modules/common/types/day.type';
import MarketsCommonPopup from '@/modules/markets/components/markets-common-popup.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import ClusterService, { ClusterServiceS } from '../cluster.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '../cluster-compsets.service';
import MarketsCompsetMainModel from '../models/markets-compset-main.model';

@Component({
    components: { MarketsCommonPopup },
})
export default class DayMarketModalPage extends Vue {
    @Inject(ClusterServiceS) protected clusterService!: ClusterService;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(MarketsFiltersServiceS) protected marketsFiltersService!: MarketsFiltersService;
    @Inject(ClusterCompsetsServiceS) protected clusterCompsetService!: ClusterCompsetsService;

    @Prop({
        required: true,
        type: Number,
    })
    public day!: Day;

    @Prop({
        type: String,
    })
    public compsetId!: string;

    @Prop({
        type: Number,
    })
    public hotelId!: number;

    get provider(): string | null {
        return this.clusterService.currentMarketsProvider;
    }

    get document() {
        return this.clusterService.getMainCompsetData<MarketsCompsetMainModel>(this.hotelId);
    }

    get marketId() {
        return this.clusterCompsetService.getMarketId(this.compsetId);
    }
}
